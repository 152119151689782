@import "src/styles/helpers";

.introduction {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 40px;

    @include media(notMobile) {
        flex-direction: row;
    }

    .introductionContent {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex: 1 0 0;

        @include media(notMobile) {
            gap: 80px;
        }

        .caseDescription {
            font-size: 14px;
            line-height: 28px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            @include media(landscape) {
                font-size: 16px;
                letter-spacing: 0.183px;
            }

            @include media(desktopAll) {
                font-size: 16px;
                letter-spacing: 0.183px;
            }

            a {
                text-decoration: underline;
                @include setProperty(color, var(--primary-500), var(--primary-900));

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .caseCollaborationStory {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .collaborationTitle {
                font-family: 'KharkivTone', sans-serif;
                font-size: 28px;
                line-height: 36px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));

                @include media(landscape) {
                    font-size: 32px;
                    line-height: 40px;
                }

                @include media(desktopAll) {
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            .collaborationText {
                font-size: 14px;
                line-height: 28px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));

                @include media(landscape) {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.183px;
                }

                @include media(desktopAll) {
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.183px;
                }
            }
        }
    }

    .introductionSideBar {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include media(portrait) {
            width: 220px;
        }

        @include media(landscape) {
            width: 300px;
        }

        @include media(desktopAll) {
            width: 300px;
        }
        
        .caseInfo {
            position: relative;
            border-radius: 16px;
            padding: 1px;
            overflow: hidden;
            align-self: stretch;
            @include setProperty(background, var(--dark-line-gradient), var(--light-line-gradient));

            .sidebarDecoration {
                position: absolute;
                top: 0;
                right: -156px;
                height: 100%;
                width: 100%;
                opacity: 0.8;

                @include media(landscape) {
                    right: -126px;
                }

                @include media(desktopAll) {
                    right: -126px;
                }
            }

            .caseInfoContent {
                border-radius: 15px;
                @include setProperty(background, var(--dark-bg), var(--light-bg));
                align-self: stretch;

                .infoItem {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    padding: 24px 32px;
                    min-width: 200px;
                    align-self: stretch;

                    &:not(:last-child) {
                        &::after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background: linear-gradient(90deg, #f6fffe00 -33%, #9bbcb733 35%, #f6fffe00 118%);
                        }
                    }

                    .infoTitle {
                        font-size: 14px;
                        line-height: 28px;
                        align-self: stretch;
                        @include setProperty(color, var(--secondary-50), var(--secondary-500));

                        @include media(landscape) {
                            font-size: 16px;
                            line-height: 28px;
                            letter-spacing: 0.183px;
                        }
                        
                        @include media(desktopAll) {
                            font-size: 16px;
                            line-height: 28px;
                            letter-spacing: 0.183px;
                        }
                    }

                    .infoText {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 600;
                        align-self: stretch;
                        @include setProperty(color, var(--secondary-50), var(--secondary-500));

                        @include media(landscape) {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        @include media(desktopAll) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            } 
        }

        .caseTechnologies {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .caseTechnologiesTitle {
                font-family: 'KharkivTone', sans-serif;
                font-size: 16px;
                line-height: 24px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
                text-align: center;

                @include media(notMobile) {
                    text-align: left;
                }
            }

            .caseTechnologiesGrid {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 18px;

                .technologyItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;

                    .technologyIconWrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 56px;
                        height: 56px;
                        border-radius: 8px;
                        @include setProperty(background, var(--secondary-700), none);
                        @include setProperty(border-color, none, var(--light-line-low-accent));

                        .technologyIcon {
                            object-fit: contain;
                            max-width: 56px;
                            max-height: 56px;
                        }
                    }

                    .technologyName {
                        font-size: 12px;
                        line-height: 14px;
                        @include setProperty(color, var(--secondary-100), var(--secondary-300));
                    }
                }
            }
        }
    }
}

.caseChallenges {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media(desktopAll) {
        gap: 48px;
    }

    .challengesTitle {
        font-family: 'KharkivTone', sans-serif;
        font-size: 28px;
        line-height: 36px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));

        @include media(landscape) {
            font-size: 32px;
            line-height: 40px;
        }

        @include media(desktopAll) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .challengesList {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .challengeItem {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &:not(:last-child) {
                padding-bottom: 24px;
                border-bottom: 1px solid;
                @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
            }

            .challengeTitle {
                font-family: 'KharkivTone', sans-serif;
                font-size: 24px;
                line-height: 32px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
            }

            .challengeText {
                font-size: 14px;
                line-height: 28px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));

                @include media(landscape) {
                    font-size: 16px;
                    letter-spacing: 0.183px;
                }

                @include media(desktopAll) {
                    font-size: 16px;
                    letter-spacing: 0.183px;
                }
            }
        }
    }
}