@import "src/styles/helpers";

.relatedCases {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 64px;
    padding-bottom: 64px;

    @include media(landscape) {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    @include media(desktopAll) {
        padding-top: 144px;
        padding-bottom: 144px;
    }

    .relatedCasesHead {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .relatedCasesTitle {
            font-family: 'KharkivTone', sans-serif;
            font-size: 32px;
            line-height: 40px;
        }

        .viewAllButton {
            .viewAllIcon {
                transform: rotate(-45deg);
            }

            @include media(mobile) {
                display: none;
            }
        }  
    }

    .relatedCaseTitle {
        font-family: 'KharkivTone', sans-serif;
        font-size: 32px;
        line-height: 40px;
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
    }

    .cases {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @include media(notMobile) {
            flex-direction: row;
        }
    }
}